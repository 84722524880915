.bpp-textDesk{
    background-color: transparent;
    color: var(--main_text);
    font-size: 16px;
    font-family: 'Uni Sans Regular';
}

.bpp-textDesk-danger{
    background-color: transparent;
    color: var(--cherry_red);
    font-size: 16px; 
    font-family: 'Uni Sans Regular';
}