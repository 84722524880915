.bpp-navigation {
    color: white;
}
.bpp-navigation-black {
    color: black
}
.bpp-navigation img {
    padding: 0px 16px
}

.bpp-navigation-container{
    background-color: var(--primary_brand);
    display: flex;
    align-items: center;
    z-index: 1;
}

.bpp-navigation-container-white{
    background-color: 'white';
    display: flex;
    align-items: center;
    z-index: 1;
}