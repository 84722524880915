.bpp-input{
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    border-bottom: 1px solid var(--gray_light_default);
    background-color: transparent
}

.bpp-input-danger{ 
    width: 100%;
    height: 20px;
    color: #E01040;
    border: 0px;
    border-bottom: 1px solid #E01040;
    background-color: #F7C3CF   
}

.bpp-input-grey{ 
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    border-bottom: 1px solid var(--brown_grey);
    background-color: transparent   
}


.bpp-input-chevron{ 
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    background-color: transparent   
}