.bpp-buttonDesk {
    background: none;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid;
    padding: 8px 0;
    font: inherit;
    outline: inherit;
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 8px;
    font-size: 120%;
    width: 100%;
    border-color: var(--accent_main_complementary);
    cursor: pointer;
}

.bpp-buttonDesk.bpp-buttonDesk-loading {
    padding: 1.5px;
}

.bpp-buttonDesk.bpp-danger {
    background-color: #FAEAEE;
    color: #e01040;
    border: 2px solid #e01040;
}

.bpp-buttonDesk.bpp-image {
    background-repeat: no-repeat;
    /* background-position: 10vw; */
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 32px;
}

.bpp-buttonDesk.bpp-image.email {
    background-image: url(../../../assets/icone_email.svg);
}

.bpp-buttonDesk.bpp-image.mensagem {
    background-image: url(../../../assets/icone_mensagem.svg);
}

.bpp-buttonDesk.bpp-image.eye {
    background-image: url(../../../assets/eye_icon.svg);
}

.bpp-buttonDesk-extract {
    padding: 8px 0;
    border-width: 0.3px;
    border-radius: 0;
    border: solid;
    border-color: var(--primary_brand);
    font: inherit;
    outline: inherit;
    font-size: 14px;
    background-color: transparent;
    color: var(--primary_brand);
    border: 2px solid;
}

.bpp-buttonDesk-extract.bpp-current {
    background-color: var(--primary_brand);
    color: var(--neutral_main_active);
    border: 0px solid var(--primary_brand);
    min-height: 34px;
}

.bpp-buttonDesk-extract:nth-of-type(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 0;
}

.bpp-buttonDesk-extract:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 0;
}

.bpp-buttonDesk-indicator-recusado {
    background-color: var(--alert_main) !important;
    background-image: url(../../../assets/icon_times_new.svg);
    background-position: 10px 3px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--alert_main);
    border-color: var(--alert_main);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-buttonDesk-indicator-aprovado {
    background-color: transparent;
    background-image: url(../../../assets/icon_check_new.svg);
    background-position: 10px 6px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-buttonDesk-indicator-pendente {
    background-color: transparent;
    background-image: url(../../../assets/icon_clock_new.svg);
    background-position: 10px 5px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-buttonDesk-indicator-transparente {
    background-color: var(--main_content_bg);
    border: none;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}