.bpp-modalDesk {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  min-width: 400px;
}

.bpp-modalDesk-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  z-index: 100
}

.bpp-modalDesk-space {
  flex: 0.4;
  z-index: 101;
}

.bpp-modalDesk-content {
  height: 40%;
  width: 42%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 101;
  align-self: center;
  min-height: 300px;
  min-width: 400px;
  max-width: 554px;
  max-height: 454px;
}

.bpp-modalDesk-content-radius {
  overflow: hidden;  
  background-color: white;
  border-radius: 8px;
  flex: 1;
  display: flex;
}

@-webkit-keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.fadeInModalDesk {
  -webkit-animation-name: fadeInModal;
  animation-name: fadeInModal;
}

@-webkit-keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.fadeOutModalDesk {
  -webkit-animation-name: fadeOutModal;
  animation-name: fadeOutModal;
}