.bpp-header-container {
    display: flex;
    padding: 16px;
    background-color: var(--primary_brand);
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    transition:flex 0.3s ease-out;
    z-index: 1;
    backface-visibility: hidden;
}