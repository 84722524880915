.bpp-modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
}

.bpp-modal-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  z-index: 101
}

.bpp-modal-space {
  flex: 0.4;
  z-index: 101;
}

.bpp-modal-content {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  z-index: 101;
}

@-webkit-keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.fadeInModal {
  -webkit-animation-name: fadeInModal;
  animation-name: fadeInModal;
}

@-webkit-keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.fadeOutModal {
  -webkit-animation-name: fadeOutModal;
  animation-name: fadeOutModal;
}