@font-face {
  font-family: 'Uni Sans Bold Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Bold_Italic.ttf)
}

@font-face {
  font-family: 'Uni Sans Bold';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Bold.ttf)
}

@font-face {
  font-family: 'Uni Sans Book';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Book.ttf)
}

@font-face {
  font-family: 'Uni Sans Book Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Book_Italic.ttf)
}

@font-face {
  font-family: 'Uni Sans Heavy';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Heavy.ttf)
}

@font-face {
  font-family: 'Uni Sans Heavy Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Heavy_Italic.ttf)
}

@font-face {
  font-family: 'Uni Sans Light';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Light.ttf)
}

@font-face {
  font-family: 'Uni Sans Light Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Light_Italic.ttf)
}

@font-face {
  font-family: 'Uni Sans Regular';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Regular.ttf)
}

@font-face {
  font-family: 'Uni Sans Regular Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Regular_Italic.ttf)
}

@font-face {
  font-family: 'Uni Sans Semi Bold';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_SemiBold.ttf)
}

@font-face {
  font-family: 'Uni Sans Semi Bold Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_SemiBold_Italic.ttf)
}

@font-face {
  font-family: 'Uni Sans Thin';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Thin.ttf)
}

@font-face {
  font-family: 'Uni Sans Thin Italic';
  font-style: normal;
  src: url(../src/assets/fonts/Fontfabric_Uni_Sans_Thin_Italic.ttf)
}

@font-face {
  font-family: 'Bpp Font';
  font-style: normal;
  src: url(../src/assets/fonts/bppfont.ttf)
}



:root {
  --primary_brand: #303e64;
  --brown_grey: #7f7a7a;
  --neutral_main_active: #ffffff;
  --main_text: #504f4f;
  --alert_secondary: #e0b710;
  --accent_main_active: #10e082;
  --main_content_bg: #f7f9fc;
  --accent_main_complementary: #0285ca;
  --accent_secondary_complementary: #6629c6;
  --alert_main: #e07310;
  --cherry_red: #e01015;
  --gray_light_default: #84888c;
  --graylight_txt: #83868b;
  --black_txt: black;
  --disabled_btn: rgba(2, 133, 202, 0.5);
}

hr {
  margin: 0
}

html, body, #root {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  background-color: var(--primary_brand);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  overflow: scroll
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Uni Sans Regular";
}

*:focus {
  outline: none;
}

::-webkit-scrollbar { 
  display: none; 
}

.bpp-input-phone{
  height: 20px;
  color: var(--gray_light_default);
  border: 0px;
  border-bottom: 1px solid var(--gray_light_default);
  background-color: transparent;
  width: 80%;
  text-align: center
}

.cameraSwitch {
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background-color: transparent;
  margin: -22px 0 0 -22px;
  z-index: 2;

}

.cameraOuterDiv {
  left: -37px;
    height: 75px;
    width: 75px;
    background-color: hsla(0,0%,100%,.4);
    z-index: 1;
    background-color: transparent;
}

.cameraContainer {
  position: absolute;
  left: 75%;
  bottom: -5px;
  background-color: transparent;
}

.cameraText {
    position: absolute;
    top: 50px;
    z-index: 5;
    margin-left: 20px;
}

#container-circles {
  bottom: 30vh !important;
}

.refresh-view {
  display: flex;
  flex-direction: column
}
/* 
.divRow{
  background-color: transparent
}

.divRow:hover{
  background-color: rgba(2,133,202, 0.2);
  border-radius: 10px
} */

.sectionList>div:first-child {
  overflow: scroll !important
}

input{
  width: 100%;
  height: 20px;
  color: var(--gray_light_default);
  border: 0px;
  border-bottom: 1px solid var(--gray_light_default);
  background-color: transparent;
  margin-bottom: 15px;
}
  .sectionList>div:first-child>div:last-child {
  display: flex;
  flex-direction: column
  }


.filledFilter {
  background-color: var(--primary_brand);
  border: 2px solid var(--primary_brand);
  border-radius: 8px;
}

.filter {

  border: 2px solid var(--primary_brand);
  border-radius: 8px;
}

#root {
  max-width: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.changePassword {
  -webkit-text-security: disc !important;
}

.div-square-filled {
  position: absolute;
  width: calc(100% - 8px) !important;
  height: calc(30% - 8px) !important;
  background: rgba(2, 133, 202, 0.7); 
  border-radius: 6px;
  bottom: 2px;
  left: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center
}

.div-square-centered {
  position: absolute;
  width: calc(100% - 8px) !important;
  height: calc(100% - 8px) !important;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid  rgba(16, 224, 130, 1);
  background-image:  url(../src/assets/check-solid.svg);
  background-repeat: no-repeat;
  border-radius: 8px;   
  background-size: 40%;
  background-position: center;    
}

@media (min-width: 500px) {
  .text-block.show-loading-animation {
    width: 80% !important
  }
}