/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

@font-face {
  font-family: 'Uni Sans Bold Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Bold_Italic.ec7a4ebe.ttf)
}

@font-face {
  font-family: 'Uni Sans Bold';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Bold.3e0aefa0.ttf)
}

@font-face {
  font-family: 'Uni Sans Book';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Book.27a709bc.ttf)
}

@font-face {
  font-family: 'Uni Sans Book Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Book_Italic.65752432.ttf)
}

@font-face {
  font-family: 'Uni Sans Heavy';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Heavy.007b381f.ttf)
}

@font-face {
  font-family: 'Uni Sans Heavy Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Heavy_Italic.21382705.ttf)
}

@font-face {
  font-family: 'Uni Sans Light';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Light.fbb0abe8.ttf)
}

@font-face {
  font-family: 'Uni Sans Light Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Light_Italic.ca1e2ccd.ttf)
}

@font-face {
  font-family: 'Uni Sans Regular';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Regular.4a8fe97a.ttf)
}

@font-face {
  font-family: 'Uni Sans Regular Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Regular_Italic.6e993886.ttf)
}

@font-face {
  font-family: 'Uni Sans Semi Bold';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_SemiBold.e9930c97.ttf)
}

@font-face {
  font-family: 'Uni Sans Semi Bold Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_SemiBold_Italic.b2e3e876.ttf)
}

@font-face {
  font-family: 'Uni Sans Thin';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Thin.0d7dc7ce.ttf)
}

@font-face {
  font-family: 'Uni Sans Thin Italic';
  font-style: normal;
  src: url(/static/media/Fontfabric_Uni_Sans_Thin_Italic.2f0ecda8.ttf)
}

@font-face {
  font-family: 'Bpp Font';
  font-style: normal;
  src: url(/static/media/bppfont.6bf8104f.ttf)
}



:root {
  --primary_brand: #303e64;
  --brown_grey: #7f7a7a;
  --neutral_main_active: #ffffff;
  --main_text: #504f4f;
  --alert_secondary: #e0b710;
  --accent_main_active: #10e082;
  --main_content_bg: #f7f9fc;
  --accent_main_complementary: #0285ca;
  --accent_secondary_complementary: #6629c6;
  --alert_main: #e07310;
  --cherry_red: #e01015;
  --gray_light_default: #84888c;
  --graylight_txt: #83868b;
  --black_txt: black;
  --disabled_btn: rgba(2, 133, 202, 0.5);
}

hr {
  margin: 0
}

html, body, #root {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  background-color: #303e64;
  background-color: var(--primary_brand);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  overflow: scroll
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Uni Sans Regular";
}

*:focus {
  outline: none;
}

::-webkit-scrollbar { 
  display: none; 
}

.bpp-input-phone{
  height: 20px;
  color: #84888c;
  color: var(--gray_light_default);
  border: 0px;
  border-bottom: 1px solid #84888c;
  border-bottom: 1px solid var(--gray_light_default);
  background-color: transparent;
  width: 80%;
  text-align: center
}

.cameraSwitch {
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background-color: transparent;
  margin: -22px 0 0 -22px;
  z-index: 2;

}

.cameraOuterDiv {
  left: -37px;
    height: 75px;
    width: 75px;
    background-color: hsla(0,0%,100%,.4);
    z-index: 1;
    background-color: transparent;
}

.cameraContainer {
  position: absolute;
  left: 75%;
  bottom: -5px;
  background-color: transparent;
}

.cameraText {
    position: absolute;
    top: 50px;
    z-index: 5;
    margin-left: 20px;
}

#container-circles {
  bottom: 30vh !important;
}

.refresh-view {
  display: flex;
  flex-direction: column
}
/* 
.divRow{
  background-color: transparent
}

.divRow:hover{
  background-color: rgba(2,133,202, 0.2);
  border-radius: 10px
} */

.sectionList>div:first-child {
  overflow: scroll !important
}

input{
  width: 100%;
  height: 20px;
  color: #84888c;
  color: var(--gray_light_default);
  border: 0px;
  border-bottom: 1px solid #84888c;
  border-bottom: 1px solid var(--gray_light_default);
  background-color: transparent;
  margin-bottom: 15px;
}
  .sectionList>div:first-child>div:last-child {
  display: flex;
  flex-direction: column
  }


.filledFilter {
  background-color: #303e64;
  background-color: var(--primary_brand);
  border: 2px solid #303e64;
  border: 2px solid var(--primary_brand);
  border-radius: 8px;
}

.filter {

  border: 2px solid #303e64;

  border: 2px solid var(--primary_brand);
  border-radius: 8px;
}

#root {
  max-width: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.changePassword {
  -webkit-text-security: disc !important;
}

.div-square-filled {
  position: absolute;
  width: calc(100% - 8px) !important;
  height: calc(30% - 8px) !important;
  background: rgba(2, 133, 202, 0.7); 
  border-radius: 6px;
  bottom: 2px;
  left: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center
}

.div-square-centered {
  position: absolute;
  width: calc(100% - 8px) !important;
  height: calc(100% - 8px) !important;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid  rgba(16, 224, 130, 1);
  background-image:  url(/static/media/check-solid.53c8c7b1.svg);
  background-repeat: no-repeat;
  border-radius: 8px;   
  background-size: 40%;
  background-position: center;    
}

@media (min-width: 500px) {
  .text-block.show-loading-animation {
    width: 80% !important
  }
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid var(--accent_main_complementary);
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent_main_complementary) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-spinner div {
    transform-origin: 32px 32px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: var(--accent_main_complementary);
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  @-webkit-keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
.lds-ring-white {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-white div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--neutral_main_active);
    border-radius: 50%;
    -webkit-animation: lds-ring-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--neutral_main_active) transparent transparent transparent;
  }
  .lds-ring-white div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-white div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-white div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-white {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-white {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-white {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-white div {
  transform-origin: 16px 16px;
  -webkit-animation: lds-spinner-white 1.2s linear infinite;
          animation: lds-spinner-white 1.2s linear infinite;
}
.lds-spinner-white div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--neutral_main_active);
}
.lds-spinner-white div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-white div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-white div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-white div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-white div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-white div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-white div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-white div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-white div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-white div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-white div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-white div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-white {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-white {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-ring-green {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-green div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--accent_main_active);
    border-radius: 50%;
    -webkit-animation: lds-ring-green 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-green 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent_main_active) transparent transparent transparent;
  }
  .lds-ring-green div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-green div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-green div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-green {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-green {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-green {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-green div {
  transform-origin: 16px 16px;
  -webkit-animation: lds-spinner-green 1.2s linear infinite;
          animation: lds-spinner-green 1.2s linear infinite;
}
.lds-spinner-green div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--accent_main_active);
}
.lds-spinner-green div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-green div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-green div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-green div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-green div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-green div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-green div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-green div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-green div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-green div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-green div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-green div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-green {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-green {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-ring-blue {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-blue div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--primary_brand);
    border-radius: 50%;
    -webkit-animation: lds-ring-blue 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-blue 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary_brand) transparent transparent transparent;
  }
  .lds-ring-blue div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-blue div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-blue div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-blue {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-blue {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-blue {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-blue div {
  transform-origin: 16px 16px;
  -webkit-animation: lds-spinner-blue 1.2s linear infinite;
          animation: lds-spinner-blue 1.2s linear infinite;
}
.lds-spinner-blue div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--primary_brand);
}
.lds-spinner-blue div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-blue div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-blue div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-blue div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-blue div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-blue div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-blue div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-blue div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-blue div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-blue div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-blue div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-blue div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-blue {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-blue {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-ring-dot {
    display: inline-block;
    position: relative;
    width: 18px; 
    height: 18px;
  }
  .lds-ring-dot div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    margin: 0px;
    border: 2px solid var(--primary_brand);
    border-radius: 50%;
    -webkit-animation: lds-ring-dot 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-dot 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary_brand) transparent transparent transparent;
  }
  .lds-ring-dot div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-dot div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-dot div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-dot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-dot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-dot {
  color: official;
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-spinner-dot div {
  transform-origin: 8px 8px;
  -webkit-animation: lds-spinner-dot 1.2s linear infinite;
          animation: lds-spinner-dot 1.2s linear infinite;
}
.lds-spinner-dot div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0.75px;
  left: 7.25px;
  width: 1.25px;
  height: 3.5px;
  border-radius: 10%;
  background: var(--primary_brand);
}
.lds-spinner-dot div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-dot div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-dot div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-dot div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-dot div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-dot div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-dot div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-dot div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-dot div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-dot div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-dot div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-dot div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-dot {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-dot {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bpp-main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    justify-content: space-between;
    background: var(--main_content_bg);
    padding-top: 32px;
    margin-top: -16px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden
}

.bpp-main-list {
    padding: 0;
    padding-top: 16px;
    margin-top: -16px;
}

.bpp-mainBackGround {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    justify-content: space-between;
    padding-top: 32px;
    margin-top: -16px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden
}

.bpp-mainBackGround:after {
    content: "";
    background: url(/static/media/backGroundUpdate.4ed03913.jpg);
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}
.bpp-modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
}

.bpp-modal-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  z-index: 101
}

.bpp-modal-space {
  flex: 0.4 1;
  z-index: 101;
}

.bpp-modal-content {
  flex: 0.6 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  z-index: 101;
}

@-webkit-keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.fadeInModal {
  -webkit-animation-name: fadeInModal;
  animation-name: fadeInModal;
}

@-webkit-keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.fadeOutModal {
  -webkit-animation-name: fadeOutModal;
  animation-name: fadeOutModal;
}
.bpp-title{
    color: black;
    font-size: 32px;
    flex: 1 1
}
.bpp-button {
    background: none;
    flex: 1 1;
    display: flex;
    justify-content: center;
    border-color: var(--accent_main_complementary);
    padding: 16px 0;
    font: inherit;
    outline: inherit;
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 8px;
    font-size: 120%;
}

.bpp-button.bpp-button-loading {
    padding: 9.5px 0;
}

.bpp-button.bpp-danger {
    background-color: #FAEAEE;
    color: #e01040;
    border: 2px solid #e01040;
}

.bpp-button.bpp-image {
    background-repeat: no-repeat;
    background-position: 10%;
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 0px;
}

.bpp-button.bpp-image.email {
    background-image: url(/static/media/icone_email.5fb9f6f8.svg);
}

.bpp-button.bpp-image.mensagem {
    background-image: url(/static/media/icone_mensagem.f4a235c2.svg);
}

.bpp-button.bpp-image.telefone:before {
    background-image: url(/static/media/icone_telefone.d26b634b.svg);
}

@media screen and (min-width: 400px) {
    .bpp-button.bpp-image.telefone {
        background-image: url(/static/media/icone_telefone.d26b634b.svg);
    }
}

@media screen and (max-width: 399px) {
    .bpp-button.bpp-image.telefone:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -33;
        transform: rotate(30deg);
        margin-top: 20px;
        left: -8px;
        background-image: url(/static/media/icone_telefone.d26b634b.svg);
        background-repeat: no-repeat;
    }
}

.bpp-button.bpp-image.eye {
    background-image: url(/static/media/eye_icon.50189a53.svg);
}

.bpp-button-extract {
    padding: 8px 0;
    border-width: 0.3px;
    border-radius: 0;
    border: solid;
    border-color: var(--primary_brand);
    font: inherit;
    outline: inherit;
    font-size: 14px;
    background-color: transparent;
    color: var(--primary_brand);
    border: 2px solid;
}

.bpp-button-extract.bpp-current {
    background-color: var(--primary_brand);
    color: var(--neutral_main_active);
    border: 0px solid var(--primary_brand);
    min-height: 34px;
}

.bpp-button-extract:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 0;
}

.bpp-button-extract:nth-last-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 0;
}

.bpp-button-indicator-recusado {
    background-color: transparent;
    background-image: url(/static/media/icon_times.79252e9a.svg);
    background-position: 10px 3px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-button-indicator-aprovado {
    background-color: transparent;
    background-image: url(/static/media/icon_check.e6756b49.svg);
    background-position: 10px 6px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-button-indicator-pendente {
    background-color: transparent;
    background-image: url(/static/media/icon_clock_regular.1b64ad4b.svg);
    background-position: 10px 5px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-button-indicator-transparente {
    background-color: var(--main_content_bg);
    border: none;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}
.bpp-text{
    background-color: transparent;
    color: var(--main_text);
    font-size: 16px;
    font-family: 'Uni Sans Regular';
}

.bpp-text-danger{
    background-color: transparent;
    color: var(--cherry_red);
    font-size: 16px; 
    font-family: 'Uni Sans Regular';
}
.bpp-mainDesk {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    background: #ffffff;
    padding-top: 32px;
    margin-top: -16px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    overflow: auto;
}

.bpp-mainDesk-modal {
    padding: 60px;
}

.bpp-mainDesk-list {
    padding: 0 10vw;
}


.bpp-mainDesk-list-no-padding {
    padding: 0 !important;
}

.bpp-titleDesk{
    color: black;
    font-size: 32px;
    flex: 1 1
}
.bpp-buttonDesk {
    background: none;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid;
    padding: 8px 0;
    font: inherit;
    outline: inherit;
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 8px;
    font-size: 120%;
    width: 100%;
    border-color: var(--accent_main_complementary);
    cursor: pointer;
}

.bpp-buttonDesk.bpp-buttonDesk-loading {
    padding: 1.5px;
}

.bpp-buttonDesk.bpp-danger {
    background-color: #FAEAEE;
    color: #e01040;
    border: 2px solid #e01040;
}

.bpp-buttonDesk.bpp-image {
    background-repeat: no-repeat;
    /* background-position: 10vw; */
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 32px;
}

.bpp-buttonDesk.bpp-image.email {
    background-image: url(/static/media/icone_email.5fb9f6f8.svg);
}

.bpp-buttonDesk.bpp-image.mensagem {
    background-image: url(/static/media/icone_mensagem.f4a235c2.svg);
}

.bpp-buttonDesk.bpp-image.eye {
    background-image: url(/static/media/eye_icon.50189a53.svg);
}

.bpp-buttonDesk-extract {
    padding: 8px 0;
    border-width: 0.3px;
    border-radius: 0;
    border: solid;
    border-color: var(--primary_brand);
    font: inherit;
    outline: inherit;
    font-size: 14px;
    background-color: transparent;
    color: var(--primary_brand);
    border: 2px solid;
}

.bpp-buttonDesk-extract.bpp-current {
    background-color: var(--primary_brand);
    color: var(--neutral_main_active);
    border: 0px solid var(--primary_brand);
    min-height: 34px;
}

.bpp-buttonDesk-extract:nth-of-type(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 0;
}

.bpp-buttonDesk-extract:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 0;
}

.bpp-buttonDesk-indicator-recusado {
    background-color: var(--alert_main) !important;
    background-image: url(/static/media/icon_times_new.b5a0cc89.svg);
    background-position: 10px 3px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--alert_main);
    border-color: var(--alert_main);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-buttonDesk-indicator-aprovado {
    background-color: transparent;
    background-image: url(/static/media/icon_check_new.046a4ec1.svg);
    background-position: 10px 6px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-buttonDesk-indicator-pendente {
    background-color: transparent;
    background-image: url(/static/media/icon_clock_new.ffc8a5bf.svg);
    background-position: 10px 5px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-buttonDesk-indicator-transparente {
    background-color: var(--main_content_bg);
    border: none;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid var(--accent_main_complementary);
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent_main_complementary) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-spinner div {
    transform-origin: 32px 32px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: var(--accent_main_complementary);
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  @-webkit-keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
.lds-ring-white {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-white div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--neutral_main_active);
    border-radius: 50%;
    -webkit-animation: lds-ring-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--neutral_main_active) transparent transparent transparent;
  }
  .lds-ring-white div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-white div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-white div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-white {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-white {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-white {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-white div {
  transform-origin: 16px 16px;
  -webkit-animation: lds-spinner-white 1.2s linear infinite;
          animation: lds-spinner-white 1.2s linear infinite;
}
.lds-spinner-white div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--neutral_main_active);
}
.lds-spinner-white div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-white div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-white div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-white div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-white div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-white div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-white div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-white div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-white div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-white div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-white div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-white div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-white {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-white {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-ring-green {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-green div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--accent_main_active);
    border-radius: 50%;
    -webkit-animation: lds-ring-green 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-green 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent_main_active) transparent transparent transparent;
  }
  .lds-ring-green div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-green div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-green div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-green {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-green {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-green {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-green div {
  transform-origin: 16px 16px;
  -webkit-animation: lds-spinner-green 1.2s linear infinite;
          animation: lds-spinner-green 1.2s linear infinite;
}
.lds-spinner-green div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--accent_main_active);
}
.lds-spinner-green div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-green div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-green div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-green div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-green div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-green div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-green div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-green div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-green div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-green div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-green div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-green div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-green {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-green {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-ring-blue {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-blue div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--primary_brand);
    border-radius: 50%;
    -webkit-animation: lds-ring-blue 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring-blue 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary_brand) transparent transparent transparent;
  }
  .lds-ring-blue div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring-blue div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring-blue div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring-blue {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes lds-ring-blue {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-blue {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-blue div {
  transform-origin: 16px 16px;
  -webkit-animation: lds-spinner-blue 1.2s linear infinite;
          animation: lds-spinner-blue 1.2s linear infinite;
}
.lds-spinner-blue div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--primary_brand);
}
.lds-spinner-blue div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner-blue div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner-blue div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner-blue div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner-blue div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner-blue div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner-blue div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner-blue div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner-blue div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner-blue div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner-blue div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner-blue div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner-blue {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner-blue {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bpp-textDesk{
    background-color: transparent;
    color: var(--main_text);
    font-size: 16px;
    font-family: 'Uni Sans Regular';
}

.bpp-textDesk-danger{
    background-color: transparent;
    color: var(--cherry_red);
    font-size: 16px; 
    font-family: 'Uni Sans Regular';
}
.bpp-card{ 
    width: 35%;
    height: 50%;
    border-radius: 16px;
    padding: 70px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 260px;
    min-width: 350px;
}

.bpp-headerDesk-container {
    height: 84px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
   
}

.bpp-headerBigDesk-container {
    height: 84px;
    display: flex;
    padding: 1vw 0 0 0;
    width: 1154px;
    flex-direction: column;
    transition:flex 0.3s ease-out;
    z-index: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    align-self: center;
}

.bpp-headerBigDesk-content {
    flex-direction: row;
    display: flex;
    flex: 0.8 1;
    align-items: center;
    justify-content: space-around;
}


@media screen and (max-width: 1154px) {
    
    .bpp-headerBigDesk-container {
        height: 84px !important;
        display: flex;
        width: auto !important;
        padding: 1vw 5vw 0 10vw !important;
        flex-direction: column;
        transition:flex 0.3s ease-out;
        z-index: 1;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        background-color: none;
        align-self: normal !important;
    }

  }
.bpp-navigation {
    color: white;
}
.bpp-navigation-black {
    color: black
}
.bpp-navigation img {
    padding: 0px 16px
}

.bpp-navigation-container{
    background-color: var(--primary_brand);
    display: flex;
    align-items: center;
    z-index: 1;
}

.bpp-navigation-container-white{
    background-color: 'white';
    display: flex;
    align-items: center;
    z-index: 1;
}
.bpp-input{
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    border-bottom: 1px solid var(--gray_light_default);
    background-color: transparent
}

.bpp-input-danger{ 
    width: 100%;
    height: 20px;
    color: #E01040;
    border: 0px;
    border-bottom: 1px solid #E01040;
    background-color: #F7C3CF   
}

.bpp-input-grey{ 
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    border-bottom: 1px solid var(--brown_grey);
    background-color: transparent   
}


.bpp-input-chevron{ 
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    background-color: transparent   
}
.bpp-navigationDesk {
    color: white;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}
.bpp-navigationDesk-black {
    color: black;
    cursor: 'pointer';
}
.bpp-navigationDesk img {
    padding: 0px 16px
}

.bpp-navigationDesk-container{
    background-color: var(--primary_brand);
    display: flex;
    align-items: center;
    z-index: 1
}

.bpp-navigationDesk-container-white{
    background-color: 'white';
    display: flex;
    align-items: center;
    z-index: 1;
    font-size: 24;
    font-family: 'Uni Sans Bold'
}
.bpp-inputDesk{
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    border-bottom: 1px solid var(--gray_light_default);
    background-color: transparent
}

.bpp-inputDesk-danger{ 
    width: 100%;
    height: 20px;
    color: #E01040;
    border: 0px;
    border-bottom: 1px solid #E01040;
    background-color: #F7C3CF   
}

.bpp-inputDesk-grey{ 
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    border-bottom: 1px solid var(--brown_grey);
    background-color: transparent   
}


.bpp-inputDesk-chevron{ 
    width: 100%;
    height: 20px;
    color: var(--gray_light_default);
    border: 0px;
    background-color: transparent   
}

.bpp-modalDesk {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  min-width: 400px;
}

.bpp-modalDesk-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  z-index: 100
}

.bpp-modalDesk-space {
  flex: 0.4 1;
  z-index: 101;
}

.bpp-modalDesk-content {
  height: 40%;
  width: 42%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 101;
  align-self: center;
  min-height: 300px;
  min-width: 400px;
  max-width: 554px;
  max-height: 454px;
}

.bpp-modalDesk-content-radius {
  overflow: hidden;  
  background-color: white;
  border-radius: 8px;
  flex: 1 1;
  display: flex;
}

@-webkit-keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.fadeInModalDesk {
  -webkit-animation-name: fadeInModal;
  animation-name: fadeInModal;
}

@-webkit-keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutModal {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.fadeOutModalDesk {
  -webkit-animation-name: fadeOutModal;
  animation-name: fadeOutModal;
}
.bpp-header-container {
    display: flex;
    padding: 16px;
    background-color: var(--primary_brand);
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    transition:flex 0.3s ease-out;
    z-index: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
