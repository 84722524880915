.bpp-button {
    background: none;
    flex: 1;
    display: flex;
    justify-content: center;
    border-color: var(--accent_main_complementary);
    padding: 16px 0;
    font: inherit;
    outline: inherit;
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 8px;
    font-size: 120%;
}

.bpp-button.bpp-button-loading {
    padding: 9.5px 0;
}

.bpp-button.bpp-danger {
    background-color: #FAEAEE;
    color: #e01040;
    border: 2px solid #e01040;
}

.bpp-button.bpp-image {
    background-repeat: no-repeat;
    background-position: 10%;
    background-color: var(--accent_main_complementary);
    color: white;
    border-radius: 0px;
}

.bpp-button.bpp-image.email {
    background-image: url(../../../assets/icone_email.svg);
}

.bpp-button.bpp-image.mensagem {
    background-image: url(../../../assets/icone_mensagem.svg);
}

.bpp-button.bpp-image.telefone:before {
    background-image: url(../../../assets/icone_telefone.svg);
}

@media screen and (min-width: 400px) {
    .bpp-button.bpp-image.telefone {
        background-image: url(../../../assets/icone_telefone.svg);
    }
}

@media screen and (max-width: 399px) {
    .bpp-button.bpp-image.telefone:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -33;
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        margin-top: 20px;
        left: -8px;
        background-image: url(../../../assets/icone_telefone.svg);
        background-repeat: no-repeat;
    }
}

.bpp-button.bpp-image.eye {
    background-image: url(../../../assets/eye_icon.svg);
}

.bpp-button-extract {
    padding: 8px 0;
    border-width: 0.3px;
    border-radius: 0;
    border: solid;
    border-color: var(--primary_brand);
    font: inherit;
    outline: inherit;
    font-size: 14px;
    background-color: transparent;
    color: var(--primary_brand);
    border: 2px solid;
}

.bpp-button-extract.bpp-current {
    background-color: var(--primary_brand);
    color: var(--neutral_main_active);
    border: 0px solid var(--primary_brand);
    min-height: 34px;
}

.bpp-button-extract:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 0;
}

.bpp-button-extract:nth-last-child(2) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 0;
}

.bpp-button-indicator-recusado {
    background-color: transparent;
    background-image: url(../../../assets/icon_times.svg);
    background-position: 10px 3px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-button-indicator-aprovado {
    background-color: transparent;
    background-image: url(../../../assets/icon_check.svg);
    background-position: 10px 6px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-button-indicator-pendente {
    background-color: transparent;
    background-image: url(../../../assets/icon_clock_regular.svg);
    background-position: 10px 5px;
    background-size: 13px;
    background-repeat: no-repeat;
    justify-content: flex-end;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}

.bpp-button-indicator-transparente {
    background-color: var(--main_content_bg);
    border: none;
    color: var(--main_content_bg);
    border-radius: 13.5px;
    font-size: 14px;
    height: 27px;
    width: 110px;
}