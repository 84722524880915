.bpp-navigationDesk {
    color: white;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}
.bpp-navigationDesk-black {
    color: black;
    cursor: 'pointer';
}
.bpp-navigationDesk img {
    padding: 0px 16px
}

.bpp-navigationDesk-container{
    background-color: var(--primary_brand);
    display: flex;
    align-items: center;
    z-index: 1
}

.bpp-navigationDesk-container-white{
    background-color: 'white';
    display: flex;
    align-items: center;
    z-index: 1;
    font-size: 24;
    font-family: 'Uni Sans Bold'
}