.bpp-mainDesk {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    background: #ffffff;
    padding-top: 32px;
    margin-top: -16px;
    backface-visibility: hidden;
    overflow: auto;
}

.bpp-mainDesk-modal {
    padding: 60px;
}

.bpp-mainDesk-list {
    padding: 0 10vw;
}


.bpp-mainDesk-list-no-padding {
    padding: 0 !important;
}
