.bpp-headerDesk-container {
    height: 84px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    backface-visibility: hidden;
   
}

.bpp-headerBigDesk-container {
    height: 84px;
    display: flex;
    padding: 1vw 0 0 0;
    width: 1154px;
    flex-direction: column;
    transition:flex 0.3s ease-out;
    z-index: 1;
    backface-visibility: hidden;
    align-self: center;
}

.bpp-headerBigDesk-content {
    flex-direction: row;
    display: flex;
    flex: 0.8;
    align-items: center;
    justify-content: space-around;
}


@media screen and (max-width: 1154px) {
    
    .bpp-headerBigDesk-container {
        height: 84px !important;
        display: flex;
        width: auto !important;
        padding: 1vw 5vw 0 10vw !important;
        flex-direction: column;
        transition:flex 0.3s ease-out;
        z-index: 1;
        backface-visibility: hidden;
        background-color: none;
        align-self: normal !important;
    }

  }