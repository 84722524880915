.bpp-main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    justify-content: space-between;
    background: var(--main_content_bg);
    padding-top: 32px;
    margin-top: -16px;
    backface-visibility: hidden
}

.bpp-main-list {
    padding: 0;
    padding-top: 16px;
    margin-top: -16px;
}

.bpp-mainBackGround {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
    justify-content: space-between;
    padding-top: 32px;
    margin-top: -16px;
    backface-visibility: hidden
}

.bpp-mainBackGround:after {
    content: "";
    background: url(../../../assets/backGroundUpdate.jpg);
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}