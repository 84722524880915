.lds-ring-white {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring-white div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    border: 3px solid var(--neutral_main_active);
    border-radius: 50%;
    animation: lds-ring-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--neutral_main_active) transparent transparent transparent;
  }
  .lds-ring-white div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring-white div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring-white div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring-white {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.lds-spinner-white {
  color: official;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-spinner-white div {
  transform-origin: 16px 16px;
  animation: lds-spinner-white 1.2s linear infinite;
}
.lds-spinner-white div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.5px;
  left: 14.5px;
  width: 2.5px;
  height: 7px;
  border-radius: 10%;
  background: var(--neutral_main_active);
}
.lds-spinner-white div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-white div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner-white div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner-white div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner-white div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner-white div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner-white div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-white div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner-white div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner-white div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner-white div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner-white div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner-white {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
