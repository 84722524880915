.bpp-card{ 
    width: 35%;
    height: 50%;
    border-radius: 16px;
    padding: 70px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 260px;
    min-width: 350px;
}
